import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I spent the day today attending `}<a parentName="p" {...{
        "href": "http://www.themoneyevent.com/"
      }}>{`The Money Event`}</a>{` in Las Vegas and spoke on a panel with `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/lynneisenhart"
      }}>{`Lynn Eisenhart`}</a>{`, Senior Program Officer at Bill & Melinda Gates Foundation, Financial Services for the Poor and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/dannyshader"
      }}>{`Danny Shader`}</a>{`, CEO `}<a parentName="p" {...{
        "href": "https://home.paynearme.com/"
      }}>{`PayNearMe`}</a>{`. Our topic was mobile money and financial inclusion.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/blog/Jake_TheMoneyEvent_small.JPG",
        "alt": null
      }}></img></p>
    <p>{`My take-away comment is that financial inclusion in the US is not about what type of mobile wallet consumers will use or the technical details of the encryption used to secure the transaction, but whether consumers can use the technology easily and reliably, whether there is a solution that works most of the time (e.g. consumers are not going to spend effort adopting a system that will only work at 10% of the merchants they frequent) and whether the system can be trusted.`}</p>
    <p>{`Cash works, as Danny points out, because it meets these criteria. Existing forms of payment with a mag-stripe plastic card (such as a prepaid debit card) work, but are still confusing to many consumers (try paying for gas at a pump, finding in-network ATMs, or understanding the nuances of selecting credit vs. debit at the POS terminal).`}</p>
    <p>{`Whatever mobile money solution/ecosystem/apps/etc is/are eventually adopted by the mainstream consumer segment in the US, it will have to be easy to use, have nearly ubiquitous acceptance, and be trusted. I know we will see some elegant solutions, including Apple Pay, which was announced yesterday, to enable mobile payments, but in my opinion we have a while to wait until we see a mobile money solution that drives financial inclusion in the US.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      